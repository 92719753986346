import { createRef } from "react"
import { Vector3 } from "three"
import hero_architecture from './assets/hero_architecture_mountainview.jpg'
import virtuscape_slider from './assets/Virtuscape_slider_explore.webp'
import product_viewer from './assets/product_viewer.jpg'
import online_retail from './assets/online_retail.webp'
import interior_design from './assets/hero_interiordesign_curvedceiling.jpg'
import architecture from './assets/architecture_photorealism1.webp'

const state = {
  sections: 7,
  pages: 7,
  zoom: 75,
  paragraphs: [
    {
      offset: 1,
      factor: 1.75,
      header: "Engage. Inspire.",
      image: hero_architecture,
      aspect: 1.51,
      text: "We bridge the gap between imagination and reality. Our cutting-edge visualisation technology helps businesses showcase their products and spaces in ways that were previously impossible."
    },
    {
      offset: 2,
      factor: 2.0,
      header: "Virtual Spaces",
      image: virtuscape_slider,
      aspect: 1.77,
      text:
        "Our AI-Driven Virtual Spaces create personalised, interactive environments that bring your vision to life. Our smart technology adapts to user preferences, delivering engaging experiences that drive decisions.",
      url: "https://www.growm.io/virtual-room-solutions/"
    },
    {
      offset: 3,
      factor: 2.25,
      header: "Product Viewer",
      image: product_viewer,
      aspect: 1.5037,
      text:
        "Our advanced 3D & AR Viewer transforms static products into dynamic 3D experiences. Featuring highly detailed and lifelike textures, interactive elements, and seamless AR integration for real-world context.",
      url: "https://www.growm.io/ar-shopping-experience/"
    },
    {
      offset: 4,
      factor: 2.0,
      header: "Online Retail",
      image: online_retail,
      aspect: 0.665,
      text:
        "Unlock emotive shopping. Our immersive 3D & AR experiences enable customers to explore products in detail, visualise them in their own spaces, and make informed decisions.",
      url: "https://www.growm.io/ecommerce/"
    },
    {
      offset: 5,
      factor: 1.75,
      header: "Interior Design",
      image: interior_design,
      aspect: 1.55,
      text:
        "Inspire and engage clients. Help them visualise their dream spaces with our interactive 3D design tools. Our technology enables real-time collaboration and feedback, streamlining the design process.",
      url: "https://www.growm.io/interior-design/"
    },
    { 
      offset: 7,
      factor: 1.05,
      header: "Architecture",
      image: architecture,
      aspect: 1.77,
      text: "Bring projects to life. Our visualisation tools help architects and developers showcase their designs in stunning detail.",
      url: "https://www.growm.io/architecture/"
    }
  ],
  stripes: [
    { offset: 0, color: "#000", height: 13 },
    { offset: 6.3, color: "#000", height: 20 }
  ],
  diamonds: [
    { x: 0, offset: 0.15, pos: new Vector3(), scale: 14, factor: 4},
    { x: 2, offset: 1.1, pos: new Vector3(), scale: 1.8, factor: 2.1 },
    { x: -5, offset: 2, pos: new Vector3(), scale: 1.8, factor: 2.5 },
    { x: 0, offset: 3.2, pos: new Vector3(), scale: 1.8, factor: 1.75 },
    { x: 0, offset: 4, pos: new Vector3(), scale: 1.8, factor: 2.5 },
    { x: 2, offset: 5.5, pos: new Vector3(), scale: 2.25, factor: 0.85 },
    { x: -5, offset: 7, pos: new Vector3(), scale: 1.8, factor: 2 }
  ],
  top: createRef()
}

export default state